!
<template>
  <div>
    <a-modal
      width="80%"
      :visible="visiable"
      :title="
        detailData?.reminderTime
          ? detailData?.reminderTime +
            '&emsp;' +
            weekdays[new Date(detailData?.reminderTime).getDay()]
          : detailData?.momentTime +
            '&emsp;' +
            weekdays[new Date(detailData?.momentTime).getDay()]
      "
      @cancel="handleCancel"
      :footer="null"
    >
      <div class="mark">
        <span>{{ detailType }}</span>
      </div>
      <div class="row">
        <div class="backlog-content" v-if="detailData?.reminderTime">
          <template v-if="detailData.subItemsList?.length > 0">
            <div
              class="row"
              v-for="(daibanItem, daibanIndex) in detailData.subItemsList"
              :key="daibanIndex"
            >
              <img
                src="@/assets/images/timeline/daiban-active.png"
                alt=""
                v-if="daibanItem.completed"
              />
              <img src="@/assets/images/timeline/daiban.png" v-else alt="" />
              <span>{{ daibanItem.subContent }}</span>
            </div>
          </template>
          <div class="backlog-content" v-else>
            <span>{{ detailData?.content }}详情2</span>
          </div>
        </div>
        <div class="backlog-content" v-else>
          <span>{{ detailData?.momentContent }}</span>
        </div>
        <div class="avatar-list">
          <div v-if="detailData?.reminderTime" class="flexCalss">
            <div
              class="avatarClass"
              v-for="i in detailData?.personnelVoList"
              :key="i.id"
            >
              <a-avatar
                v-if="i.avatar"
                :src="setAvatar(i.avatar)"
                @click="handleGoToContact(i)"
                class="headerUrl"
              />
              <div
                v-else
                @click="handleGoToContact(i)"
                class="headerText headerTextC"
              >
                <div v-if="i.name">
                  {{ i.name[0] }}
                </div>
              </div>
              <div class="textMl" @click="handleGoToContact(i)">
                {{ i.name }}
              </div>
            </div>
          </div>
          <div v-else class="flexCalss">
            <div
              class="avatarClass"
              v-for="i in detailData?.momentContactVOList"
              :key="i.id"
            >
              <a-avatar
                v-if="i.avatar"
                :src="setAvatar(i.avatar)"
                @click="handleGoToContact(i)"
                class="headerUrl"
              />
              <div v-else @click="handleGoToContact(i)" class="headerText">
                <div v-if="i.name">
                  {{ i.name[0] }}
                </div>
              </div>
              <div class="textMl" @click="handleGoToContact(i)">
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="backlog-img-list"
          v-if="detailData?.filesRecordsList?.length > 0"
        >
          <a-image-preview-group
            :preview="{
              imageGroupVisiable,
              onVisibleChange: vis => (imageGroupVisiable = vis),
            }"
            class='itemImage'
          >
            <template
              v-for="(imgItem, imgIndex) in detailData.filesRecordsList"
              :key="imgIndex"
            >
              <div
                class="backlog-img-item"
                v-if="
                  imgItem.type === 'jpg' ||
                  imgItem.type === 'jpeg' ||
                  imgItem.type === 'png' ||
                  imgItem.type === 'svg' ||
                  imgItem.type === 'gif' ||
                  imgItem.type === 'webp' ||
                  imgItem.type === 'image'

                "
              >
                <a-dropdown>
                  <img
                    class="menu-btn"
                    src="@/assets/images/timeline/menu-btn.png"
                    alt=""
                  />
                  <template #overlay>
                    <a-menu>
                      <!-- <a-menu-item>
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >查看大图</a
                                  >
                                </a-menu-item> -->
                      <a-menu-item>
                        <a
                          :href="imgItem.dataUrlCustom"
                          :download="imgItem.originalFileName"
                          style="color: #3d3d3d"
                          >下载</a
                        >
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>

                <a-image
                  class='imageCla'
                  :src="imgItem.dataUrlCustom"
                  @click="imageGroupVisiable = true"
                />
              </div>
            </template>
          </a-image-preview-group>
        </div>
        <div
          class="backlog-file-list"
          v-if="detailData?.filesRecordsList?.length > 0"
        >
          <template
            v-for="(fileItem, fileIndex) in detailData.filesRecordsList"
            :key="fileIndex"
          >
            <div
              class="backlog-file-item"
              :class="fileItem?.type"
              v-if="
                fileItem.type != 'jpg' &&
                fileItem.type != 'jpeg' &&
                fileItem.type != 'png' &&
                fileItem.type != 'svg' &&
                fileItem.type != 'gif' &&
                fileItem.type != 'webp' &&
                fileItem.type != 'image'
              "
            >
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/zip-icon.png"
                alt=""
                v-if="fileItem.type == 'zip'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/doc-icon.png"
                alt=""
                v-else-if="fileItem.type == 'doc' || fileItem.type == 'docx'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/mp3-icon.png"
                alt=""
                v-else-if="fileItem.type == 'mp3'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/mp4-icon.png"
                alt=""
                v-else-if="fileItem.type == 'mp4'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/pdf-icon.png"
                alt=""
                v-else-if="fileItem.type == 'pdf'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/ppt-icon.png"
                alt=""
                v-else-if="fileItem.type == 'ppt' || fileItem.type == 'pptx'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/xlsx-icon.png"
                alt=""
                v-else-if="fileItem.type == 'xlsx'"
              />
              <img
                class="backlog-file-icon"
                src="@/assets/images/timeline/file-icon.png"
                alt=""
                v-else
              />
              <div class="backlog-file-message">
                <span class="backlog-file-title">{{
                  fileItem.originalFileName
                }}</span>
                <span class="backlog-file-size">{{
                  (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                }}</span>
              </div>
              <a-dropdown>
                <img
                  class="menu-btn"
                  src="@/assets/images/timeline/menu-btn-white.png"
                  alt=""
                />
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a
                        :href="fileItem.dataUrlCustom"
                        :download="fileItem.originalFileName"
                        style="color: #3d3d3d"
                        >下载</a
                      >
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </div>
        <div class="child" v-if="detailData?.contactTodoMomentVo">
          <div class="backlog-content">
            <span>{{ detailData.contactTodoMomentVo?.momentContent }}</span>
          </div>
          <div class="avatar-list">
            <div class="flexCalss">
              <div
                class="avatarClass"
                v-for="i in detailData.contactTodoMomentVo?.momentContactVOList"
                :key="i.id"
              >
                <a-avatar
                  v-if="i.avatar"
                  :src="setAvatar(i.avatar)"
                  @click="handleGoToContact(i)"
                  class="headerUrl"
                />
                <div v-else @click="handleGoToContact(i)" class="headerText">
                  <div v-if="i.name">
                    {{ i.name[0] }}
                  </div>
                </div>
                <div class="textMl" @click="handleGoToContact(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="backlog-img-list"
            v-if="detailData.contactTodoMomentVo?.filesRecordsList?.length > 0"
          >
            <a-image-preview-group
              :preview="{
                imageGroupVisiable,
                onVisibleChange: vis => (imageGroupVisiable = vis),
              }"
            >
              <template
                v-for="(imgItem, imgIndex) in detailData.contactTodoMomentVo
                  .filesRecordsList"
                :key="imgIndex"
              >
                <div
                  class="backlog-img-item"
                  v-if="
                    imgItem.type == 'jpg' ||
                    imgItem.type == 'jpeg' ||
                    imgItem.type == 'png' ||
                    imgItem.type == 'svg' ||
                    imgItem.type == 'gif'
                  "
                >
                  <a-dropdown>
                    <img
                      class="menu-btn"
                      src="@/assets/images/timeline/menu-btn.png"
                      alt=""
                    />
                    <template #overlay>
                      <a-menu>
                        <!-- <a-menu-item>
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >查看大图</a
                                  >
                                </a-menu-item> -->
                        <a-menu-item>
                          <a
                            :href="imgItem.dataUrlCustom"
                            :download="imgItem.originalFileName"
                            style="color: #3d3d3d"
                            >下载</a
                          >
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                  <a-image
                    :width="184"
                    :height="104"
                    :src="imgItem.dataUrlCustom"
                    @click="imageGroupVisiable = true"
                  />
                </div>
              </template>
            </a-image-preview-group>
          </div>
          <div
            class="backlog-file-list"
            v-if="detailData.contactTodoMomentVo?.filesRecordsList?.length > 0"
          >
            <template
              v-for="(fileItem, fileIndex) in detailData.contactTodoMomentVo
                .filesRecordsList"
              :key="fileIndex"
            >
              <div
                class="backlog-file-item"
                :class="fileItem.type"
                v-if="
                  fileItem.type != 'jpg' &&
                  fileItem.type != 'jpeg' &&
                  fileItem.type != 'png' &&
                  fileItem.type != 'svg' &&
                  fileItem.type != 'gif'
                "
              >
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/zip-icon.png"
                  alt=""
                  v-if="fileItem.type == 'zip'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/doc-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'doc' || fileItem.type == 'docx'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/mp3-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'mp3'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/mp4-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'mp4'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/pdf-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'pdf'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/ppt-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'ppt' || fileItem.type == 'pptx'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/xlsx-icon.png"
                  alt=""
                  v-else-if="fileItem.type == 'xlsx'"
                />
                <img
                  class="backlog-file-icon"
                  src="@/assets/images/timeline/file-icon.png"
                  alt=""
                  v-else
                />
                <div class="backlog-file-message">
                  <span class="backlog-file-title">{{
                    fileItem.originalFileName
                  }}</span>
                  <span class="backlog-file-size">{{
                    (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                  }}</span>
                </div>
                <a-dropdown>
                  <img
                    class="menu-btn"
                    src="@/assets/images/timeline/menu-btn-white.png"
                    alt=""
                  />
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a
                          :href="fileItem.dataUrlCustom"
                          :download="fileItem.originalFileName"
                          style="color: #3d3d3d"
                          >下载</a
                        >
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import timelineApi from '@/api/timeline';
import {
  Menu as AMenu,
  Dropdown as ADropdown,
  Image as AImage,
  Avatar as AAvatar,
} from 'ant-design-vue';
import { setAvatar } from '@/utils';

const AMenuItem = AMenu.Item;

const props = defineProps({
  visiable: Boolean,
  detailData: Object,
});

const weekdays = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const emits = defineEmits(['closeModal']);

const handleCancel = () => {
  emits('closeModal', false);
};

const detailData = ref();
const detailType = ref('');
const recordTypeList = ref([]);

//获取记录类型列表
const getRecordType = () => {
  timelineApi.getRecordType('', {}).then(res => {
    if (res) {
      recordTypeList.value = res.filter(item => item.id != 2095);
    }
  });
};

watch(
  () => props.detailData,
  (newVal, oldVal) => {
    detailData.value = newVal;
    console.log(detailData.value);
    if (detailData.value?.reminderTime) {
      detailType.value = '待办';
    } else {
      let typeItem = recordTypeList.value.find(
        item => item.itemCode === detailData.value.momentType
      );
      detailType.value = typeItem.itemNameCn;
    }
  }
);

onMounted(() => {
  getRecordType();
});
</script>

<style lang="less" scoped>
.mark {
  padding: 2px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fffaf6;
  border: 0.5px solid #ff7b00;
  position: absolute;
  top: 13px;
  left: 260px;

  span {
    color: #ff7b00;
  }
}

.backlog-content {
  width: 435px;
  // padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .row {
    width: 100%;
    padding: 13px 0;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    gap: 8px;

    img {
      width: 14px;
      height: 14px;
      object-fit: cover;
    }
  }
}

.backlog-img-list {
  width: 100%;
  margin-top: 13px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  .itemImage {
    display: flex;
  }

  .backlog-img-item {
    //width: 184px;
    height: 104px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-right: 20px;
    .imageCla {
      height: 104px;
    }
    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }

    ::v-deep(.ant-image-img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .backlog-img-item /deep/ .ant-image {
    //width: 100% !important;
    height: 104px;
  }

}

.backlog-file-list {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .backlog-file-item {
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background: #eaefff;

    &.zip,
    &.mp3 {
      background: #faedff;
    }

    &.xlsx {
      background: #e7fff5;
    }

    &.ppt,
    &.pdf {
      background: #ffefdd;
    }

    &.doc,
    &.mp4 {
      background: #eaefff;
    }

    .backlog-file-icon {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }

    .backlog-file-message {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .backlog-file-title {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      .backlog-file-size {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      margin-left: auto;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}

.avatar-list {
  width: 100%;
  margin-top: 16px;

  .flexCalss {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 16px;
  }

  .avatarClass {
    //width: 400px;
    //width: 294px;
    padding: 4px 7px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    background: #ffffff;
    border: 0.5px solid #ff7b00;

    .headerText {
      min-width: 26px;
    }
    .textMl {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
      width: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.child {
  width: 100%;
  margin-top: 16px;
}
</style>
